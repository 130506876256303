/* PaymentForm.css
.payment-form-container {
  max-width: 400px;
  margin: 50px auto;
  padding: 20px;
  background: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.title {
  font-size: 24px;
  font-weight: bold;
  color: #333;
  text-align: center;
  margin-bottom: 20px;
}

.payment-form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.card-element-wrapper {
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background: #fff;
}

.card-element {
  font-size: 16px;
  font-family: "Arial", sans-serif;
}

.pay-button {
  background-color: #4caf50;
  color: #fff;
  border: none;
  padding: 12px 20px;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.pay-button:hover {
  background-color: #45a049;
}

.pay-button:disabled {
  background-color: #ddd;
  cursor: not-allowed;
}

.payment-status {
  text-align: center;
  font-size: 14px;
  margin-top: 10px;
}

.payment-status:before {
  content: "🔔";
  margin-right: 5px;
} */

.payment-form-container {
  background-color: #fff;
  width: 100%;
  max-width: 700px;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  font-family: Arial, sans-serif;
}

.title {
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 20px;
}

.payment-form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.card-element-wrapper {
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 10px;
}

.card-element {
  width: 100%;
  height: 40px;
  border: none;
  outline: none;
}

.payment-status {
  color: green;
  font-size: 14px;
  text-align: center;
}

.buy-now-button {
  background-color: #4caf50;
  color: white;
  padding: 10px;
  font-size: 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  text-align: center;
}

.buy-now-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.intimation-note {
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  font-family: Arial, sans-serif;
  max-width: 600px;
  margin: 20px auto;
}

.intimation-note h2 {
  font-size: 20px;
  margin-bottom: 15px;
  color: #333;
  text-align: center;
}

.intimation-note p {
  font-size: 16px;
  color: #555;
  line-height: 1.6;
}

.intimation-note ul {
  margin: 10px 0 20px 0;
  padding-left: 20px;
}

.intimation-note ul li {
  font-size: 16px;
  color: #444;
  margin-bottom: 10px;
}

.intimation-note a {
  color: #4caf50;
  text-decoration: none;
}

.intimation-note a:hover {
  text-decoration: underline;
}

.intimation-note strong {
  color: #333;
}
